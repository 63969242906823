import { View } from 'app/native'
import Svg, { Path, Mask } from 'react-native-svg';
import { styled } from 'nativewind'
import { motify, useAnimationState } from 'moti'
import { memo, useMemo, useEffect } from 'react';

import fastHashCode from 'app/lib/fastHashCode';
import { ViewProps } from "react-native";

const StyledPath = styled(Path, { classProps: ["fill", "stroke"] });
const MotifiedSvg = motify(Svg)()
const MotifiedView = motify(View)()

export type Direction = "s" | "e" | "w" | "n" | "nw" | "ne" | "sw" | "se";

export function directionFromInput(input: string): Direction | null {
  const rotation = fastHashCode(input, { forcePositive: true }) % 4;
  const number = rotation * 90;
  switch (number) {
    case 0:
      return "sw";
    case 90:
      return "nw";
    case 180:
      return "ne";
    case 270:
      return "se";
  }

  return null;
}

export const LogoOne = memo(({ mask, fill, stroke, directionInput, rotation, active, direction, size, children, delay, animationDuration, scale, ...props }: ViewProps & { mask?: boolean, fill?: string, stroke?: string, children?: any, rotation?: string, directionInput?: string, direction?: Direction | null, active?: boolean, size?: number, delay?: number, animationDuration?: number, scale?: number }) => {

  animationDuration = animationDuration || animationDuration == 0 ? animationDuration : 0;

  const scaleIn = useAnimationState({
    from: {
      width: animationDuration == 0 ? size : 0,
      height: animationDuration == 0 ? size : 0,
    },
    open: {
      width: size,
      height: size,
    },
  });
  const fadeIn = useAnimationState({
    from: {
      opacity: animationDuration == 0 ? 1 : 0,
    },
    open: {
      opacity: 1,
    },
  });

  useEffect(() => {
    if (animationDuration == 0) return;

    setTimeout(() => {
      requestAnimationFrame(() => {
        scaleIn.transitionTo("open");
      });
      const timeout = setTimeout(() => {
        requestAnimationFrame(() => {
          fadeIn.transitionTo("open");
        });
      }, animationDuration);

      return () => {
        clearTimeout(timeout);
      }
    }, delay || 0);
  }, [delay]);

  const maskId = useMemo(() => `${Math.random()}`, []);
  const d = useMemo(() => "M5.99001 58.24C13.5 35.16 29.75 17.22 52.36 8.16C75.29 -1.01 101.58 -0.600001 125.86 0.689999C160.49 3.39 189.92 19.08 203.42 52.37C210.19 68.9 211.63 88.09 211.55 105.79C211.65 123.49 210.17 142.68 203.42 159.21C189.92 192.5 160.48 208.2 125.86 210.89C121.32 211.13 116.7 211.35 112.06 211.47V211.68H6.55744e-06V105.79C-0.0599934 90.23 1.05001 73.08 5.98001 58.23L5.99001 58.24Z", []);

  let rotationString: string | undefined = rotation;
  const useDirection = direction || directionFromInput(directionInput || "");
  if (!rotation && useDirection) {
    rotationString = {
      sw: "0",
      nw: "90",
      ne: "180",
      se: "270",
      s: "315",
      e: "225",
      n: "135",
      w: "45",
    }[useDirection];
  }

  if (!rotationString) {
    rotationString = "0";
  }

  if (active) {
    if (parseInt(rotationString) >= 180) {
      rotationString = "315";
    } else {
      rotationString = "-45";
    }
  }

  const outerRotation = useMemo(() => (parseInt(rotationString || "0") - 90), [rotationString]);

  return (
    <View
      {...props}
    >
      {
        // Apply scale hack because of animation might reveal the edges of the mask
      }
      <MotifiedView
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: size,
          height: size,
        }}
        transition={{
          type: "timing",
          duration: animationDuration,
        }}
        animate={{
          transform: [{ rotate: outerRotation + "deg" }],
        }}
      >
        <MotifiedView
          state={scaleIn}
          // transition={{
          //   type: "timing",
          //   duration: animationDuration,
          // }}
        >
          <MotifiedSvg
            viewBox="0 0 212 212"
            transition={{
              type: "timing",
              duration: animationDuration,
            }}
            animate={{
              scale: scale || 1,
              transform: [{ rotate: "90deg" }],
            }}
          >
            <Mask id={maskId} fill="black" >
              <Path d={d} fill="white" />
            </Mask>
            <StyledPath className="" d={d} stroke={stroke} fill={fill} mask={`url(#${maskId})`} />
          </MotifiedSvg>
        </MotifiedView>
      </MotifiedView>
      <MotifiedView
        state={fadeIn}
        transition={{
          type: "timing",
        }}
        style={{
          width: size,
          height: size,
        }}
      >
        {<View className="w-full h-full flex items-center justify-center scale-[0.99]">{children}</View>}
      </MotifiedView>
    </View>

  );
});

