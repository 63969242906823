import { useState, useEffect } from 'react';
import useProjection from 'app/hooks/useProjection'
import usePerson from 'app/hooks/usePerson'

type OnboardingStepType = "login" | "createOase" | "installApp" | "" | null;

export default function usePersonOnboarding(): [OnboardingStepType, undefined | string[]] {
  const [nextStep, setNextStep] = useState<OnboardingStepType>(null);
  const person = usePerson();

  const [personOnboarding] = useProjection(person?.id ? `onboarding:person:v2:${person?.id}` : "");

  useEffect(() => {
    function did(achievement: string) {
      // @ts-ignore
      return personOnboarding?.collection?.achievements?.includes(achievement);
    }

    let onboardingStep: OnboardingStepType = null;
    if (!personOnboarding?.loading) {
      if (did("joined_oase")) {
        if (person?.ephemeral) {
          onboardingStep = "login";
        } else if (!did("installed_app")) {
          onboardingStep = "installApp";
        }
      }
      if (!onboardingStep && !did("oase_started")) {
        onboardingStep = "createOase";
      }

    }

    setNextStep(onboardingStep as OnboardingStepType);
  }, [personOnboarding?.collection]);

  // @ts-ignore
  return [nextStep, personOnboarding?.collection?.achievements];
}

